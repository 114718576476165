import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";

import { DietarySupplementService } from "@app/core/services/dietary-supplement.service";
import { MonitoringService } from "@app/core/services/monitoring.service";
import { ToastService } from "@app/core/services/toast.service";
import * as fromDietarySupplement from "../../store";
import * as fromDietarySupplementCategories from "../actions/dietary-supplement-categories.actions";

@Injectable()
export class DietarySupplementCategoriesEffects {
  loadCategories$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDietarySupplementCategories.loadDSCategories),
      switchMap(() => {
        return this.dietarySupplementService.loadCategories().pipe(
          map((response) => {
            return fromDietarySupplementCategories.loadDSCategoriesSuccess({
              categories: response,
            });
          }),
          catchError((error) => {
            return of(
              fromDietarySupplementCategories.loadDSCategoriesFailure({
                error,
              }),
            );
          }),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store$: Store<fromDietarySupplement.State>,
    private dietarySupplementService: DietarySupplementService,
    private toastService: ToastService,
    private monitoringService: MonitoringService,
  ) {}
}
