import * as fromRoot from "@app/store";
import {
  Action,
  combineReducers,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import { fromDiarySettings, fromQuestionsSettings } from "..";
import * as fromDSCategories from "./dietary-supplement-categories.reducer";
import * as fromDS from "./dietary-supplement.reducer";
import * as fromPatient from "./patient.reducer";
import * as fromProduct from "./product.reducer";
import * as fromQuestions from "./questions.reducer";
export * as fromDiarySettings from "./diary-settings.reducer";
export * as fromQuestionsSettings from "./questions-settings.reducer";

export const profileFeatureKey = "profile";

export interface State extends fromRoot.State {
  [fromPatient.patientFeatureKey]: fromPatient.State;
  [fromQuestions.questionsFeatureKey]: fromQuestions.State;
  [fromQuestionsSettings.questionsSettingsFeatureKey]: fromQuestionsSettings.State;
  [fromProduct.productsFeatureKey]: fromProduct.State;
  [fromDiarySettings.diarySettingsFeatureKey]: fromDiarySettings.State;
  [fromDSCategories.fromDietarySupplementCategoriesFeatureKey]: fromDSCategories.State;
  [fromDS.productsFeatureKey]: fromDS.State;
}

export function reducers(state: State | undefined, action: Action) {
  return combineReducers({
    [fromDSCategories.fromDietarySupplementCategoriesFeatureKey]:
      fromDSCategories.reducer,
    [fromDS.productsFeatureKey]: fromDS.reducer,
    [fromPatient.patientFeatureKey]: fromPatient.reducer,
    [fromQuestions.questionsFeatureKey]: fromQuestions.reducer,
    [fromQuestionsSettings.questionsSettingsFeatureKey]:
      fromQuestionsSettings.reducer,
    [fromProduct.productsFeatureKey]: fromProduct.reducer,
    [fromDiarySettings.diarySettingsFeatureKey]: fromDiarySettings.reducer,
  })(state, action);
}

export const getProfileState = createFeatureSelector<State>(profileFeatureKey);

export const selectPatientState = () =>
  createSelector(
    getProfileState,
    (state: State) => state[fromPatient.patientFeatureKey],
  );

export const selectQuestionsState = () =>
  createSelector(
    getProfileState,
    (state: State) => state[fromQuestions.questionsFeatureKey],
  );

export const selectQuestionsSettingsState = () =>
  createSelector(
    getProfileState,
    (state: State) => state[fromQuestionsSettings.questionsSettingsFeatureKey],
  );

export const selectProductState = () =>
  createSelector(
    getProfileState,
    (state: State) => state[fromProduct.productsFeatureKey],
  );

export const selectDiarySettings = () =>
  createSelector(
    getProfileState,
    (state: State) => state[fromDiarySettings.diarySettingsFeatureKey],
  );

export const selectDSCategoriesState = () =>
  createSelector(
    getProfileState,
    (state: State) =>
      state[fromDSCategories.fromDietarySupplementCategoriesFeatureKey],
  );

export const selectDSProductState = () =>
  createSelector(
    getProfileState,
    (state: State) => state[fromDS.productsFeatureKey],
  );
