import { selectPatientState } from "@app/profile/store/reducers";
import * as fromRoot from "@app/store/selectors/router.selectors";
import { createSelector } from "@ngrx/store";
import * as fromPatient from "../reducers/patient.reducer";
import { selectToken } from "../reducers/patient.reducer";
import { selectedDayId } from "./diary-settings.selectors";

export const selectPatientHash = fromRoot.selectRouteFragment;
export const selectActivePatientPageData = () =>
  fromRoot.selectRouterParamData("page");

export const selectIsLoading = () =>
  createSelector(selectPatientState(), fromPatient.selectIsLoading);

export const selectIsLoaded = () =>
  createSelector(selectPatientState(), fromPatient.selectIsLoaded);

export const selectPatientId = () =>
  createSelector(selectPatientState(), fromPatient.selectPatientId);

export const selectCurrentPatient = () =>
  createSelector(selectPatientState(), fromPatient.selectPatient);

export const selectCompletedStepsCount = () =>
  createSelector(selectPatientState(), fromPatient.selectCompletedStepsCount);

export const selectProfileInfo = () =>
  createSelector(selectPatientState(), fromPatient.selectProfileInfo);

export const selectDiaryState = () =>
  createSelector(selectPatientState(), fromPatient.selectDiary);

export const selectCurrentDiary = () =>
  createSelector(selectDiaryState(), selectedDayId(), (diary, dayID): any => {
    if (diary && dayID) {
      return diary[dayID - 1];
    }
  });

export const selectAuthToken = () =>
  createSelector(selectPatientState(), selectToken);

export const selectQuestionnaireIsComplete = () =>
  createSelector(
    selectPatientState(),
    fromPatient.selectQuestionnaireIsComplete,
  );
