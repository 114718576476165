import * as fromQuestionsSettings from "@app/profile/store/reducers/questions-settings.reducer";
import { createSelector } from "@ngrx/store";
import { selectQuestionsSettingsState } from "../reducers";

export const selectAllSettings = () =>
  createSelector(
    selectQuestionsSettingsState(),
    fromQuestionsSettings.selectSettingState,
  );

export const selectOnlyParameter = () =>
  createSelector(
    selectAllSettings(),
    (state: fromQuestionsSettings.State) => state.only,
  );
