import * as Utils from "@app/shared/utils/utils";
import * as fromRoot from "@app/store";
import { createSelector } from "@ngrx/store";
import { selectDiarySettings } from "../reducers";
import * as fromDiarySettings from "../reducers/diary-settings.reducer";
import {
  selectActiveEatingID,
  selectCurrentDayID,
  selectCurrentRationID,
  selectCurrentWeakID,
} from "../reducers/diary-settings.reducer";

export const selectActiveDayId = fromRoot.selectRouterParam("day_id");
export const selectActiveEatingId = fromRoot.selectRouterParam("eating_id");
export const selectActiveEatingFoodId =
  fromRoot.selectRouterParam("eating_food_id");

export const selectIsDiaryLoading = () =>
  createSelector(selectDiarySettings(), fromDiarySettings.selectIsLoading);

export const selectedDayId = () =>
  createSelector(selectDiarySettings(), selectCurrentDayID);

export const activeEatingId = () =>
  createSelector(selectDiarySettings(), selectActiveEatingID);

export const selectedWeakId = () =>
  createSelector(selectDiarySettings(), selectCurrentWeakID);

export const selectRationId = () =>
  createSelector(selectDiarySettings(), selectCurrentRationID);

export const selectEatings = () =>
  createSelector(selectDiarySettings(), fromDiarySettings.selectEatings);

export const selectEatingEntities = () =>
  createSelector(selectEatings(), (eatings: any[]) => {
    if (eatings.length) {
      return Utils.convertArrayToObject(eatings, "id");
    }
  });

export const selectCurrentEating = () =>
  createSelector(
    selectEatingEntities(),
    selectActiveEatingId,
    (eatingEntities, activeEatingId) => {
      if (eatingEntities && activeEatingId) {
        return eatingEntities[+activeEatingId];
      }
    },
  );

export const selectWeeks = () =>
  createSelector(selectDiarySettings(), fromDiarySettings.selectWeeks);

export const selectWeek = () =>
  createSelector(selectDiarySettings(), fromDiarySettings.selectWeek);

export const selectWeekEntities = () =>
  createSelector(selectWeek(), (week: any[]) => {
    if (week.length) {
      return Utils.convertArrayToObject(week, "id");
    }
  });

export const selectCurrentDay = () =>
  createSelector(
    selectWeekEntities(),
    selectActiveDayId,
    (weekEntities, activeDayId) => {
      if (weekEntities && activeDayId) {
        return weekEntities[+activeDayId];
      }
    },
  );

export const selectDescription = () =>
  createSelector(selectDiarySettings(), fromDiarySettings.selectDescription);
