import { createSelector } from "@ngrx/store";
import { selectDSProductState } from "../reducers";
import * as fromDietarySupplement from "../reducers/dietary-supplement.reducer";
import * as fromDiarySettingsSelectors from "./diary-settings.selectors";

export const selectProfileDSProductsIsLoading = () =>
  createSelector(selectDSProductState(), fromDietarySupplement.selectIsLoading);

export const selectProfileDSProductsIsLoaded = () =>
  createSelector(selectDSProductState(), fromDietarySupplement.selectIsLoaded);

export const selectProfileDSProductsIsFirstLoad = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectIsFirstLoad,
  );

export const selectProfileDSProductIds = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectProductIds,
  );

export const selectProfileDSProductEntities = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectProductEntities,
  );

export const selectAllDSProducts = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectAllProducts,
  );

export const selectProfileDSProductTotal = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectProductTotal,
  );

export const selectProfileDSProductFilter = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectProductFilter,
  );

export const selectAllDSProductsAddedToEating = () =>
  createSelector(
    selectAllDSProducts(),
    fromDiarySettingsSelectors.selectCurrentEating(),
    (allProducts: any, currentEating: any) => {
      if (allProducts.length && currentEating) {
        return allProducts.map((product: any) => ({
          ...product,
          isAddedToEating: currentEating.food.some(
            (item: any) => item.product_id === product.id,
          ),
          product_id: product.id,
          id:
            currentEating.food.find(
              (item: any) => item.product_id === product.id,
            )?.id || null,
        }));
      }
    },
  );

export const selectCurrentRecommendedDSProductId = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.getSelectedRecommendedProductId,
  );

export const selectCurrentRecommendedDSProduct = () =>
  createSelector(
    selectCurrentRecommendedDSProductId(),
    selectProfileDSProductEntities(),
    (currentRecommendedProductId: any, productEntities: any) => {
      return productEntities[currentRecommendedProductId];
    },
  );

export const selectAllDSProductsRecommendation = () =>
  createSelector(
    selectAllDSProducts(),
    selectCurrentRecommendedDSProductId(),
    (allProducts, сurrentRecommendedProductId) => {
      return allProducts.map((product) => ({
        ...product,
        isActive: product.product_id === сurrentRecommendedProductId,
        isRecommended: true,
      }));
    },
  );

export const selectMetaOfDSProducts = () =>
  createSelector(
    selectDSProductState(),
    fromDietarySupplement.selectMetaOfDSProducts,
  );
