import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class DiaryRecipesService {
  constructor(private http: HttpClient) {}

  loadRecipes(recipeFilter: any, take = 50): Observable<any> {
    const { page, ...filter } = recipeFilter;

    return this.http.get<any>(`${environment.primaryApiUrl}/recipes`, {
      params: {
        page,
        per_limit: take,
        filter: JSON.stringify(filter),
      },
    });
  }

  getUpdatedMass(mass: number, product_id: number) {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/recipes/change/mass?id=${product_id}&mass=${mass}`,
    );
  }
}
