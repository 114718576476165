import { selectQuestionsState } from "@app/profile/store/reducers";
import * as fromQuestions from "@app/profile/store/reducers/questions.reducer";
import { createSelector } from "@ngrx/store";

export const selectAllQuestions = () =>
  createSelector(selectQuestionsState(), fromQuestions.selectAllQuestions);

export const selectQuestionTotal = () =>
  createSelector(selectQuestionsState(), fromQuestions.selectQuestionTotal);

export const selectQuestionIds = () =>
  createSelector(selectQuestionsState(), fromQuestions.selectQuestionIds);

export const selectQuestionEntities = () =>
  createSelector(selectQuestionsState(), fromQuestions.selectQuestionEntities);

export const selectCurrentQuestionId = () =>
  createSelector(selectQuestionsState(), fromQuestions.getSelectedQuestionId);

export const selectCurrentQuestion = () =>
  createSelector(
    selectQuestionEntities(),
    selectCurrentQuestionId(),
    (questionEntities, questionId) => questionEntities[questionId],
  );
