import { DietarySuppmentCategory } from "@app/shared/models/dietary-supplement/category.model";
import { createAction, props } from "@ngrx/store";

export const loadDSCategories = createAction(
  "[Profile Dietary supplement Categories / API] Load Categories",
);
export const loadDSCategoriesSuccess = createAction(
  "[Profile Dietary supplement Categories / API] Load Categories Success",
  props<{ categories: DietarySuppmentCategory[] }>(),
);
export const loadDSCategoriesFailure = createAction(
  "[Profile Dietary supplement Categories / API] Load Categories Failure",
  props<{ error: any }>(),
);
