import { createSelector } from "@ngrx/store";
import { selectDSCategoriesState } from "../reducers";
import * as fromDietarySuppmentCategories from "../reducers/dietary-supplement-categories.reducer";

export const selectProfileDSCategoryIsLoading = () =>
  createSelector(
    selectDSCategoriesState(),
    fromDietarySuppmentCategories.selectLoading,
  );
export const selectProfileDSCategoryCategories = () =>
  createSelector(
    selectDSCategoriesState(),
    fromDietarySuppmentCategories.selectCategories,
  );
