import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@env/environment";

@Injectable()
export class DiaryProductsService {
  readonly http = inject(HttpClient);

  getUpdatedMass(mass: number, product_id: number) {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/products/change/mass?id=${product_id}&mass=${mass}`,
    );
  }
}
