import { GreetingOnly } from "@app/profile/pages/questions-greeting/questions-greeting.component";
import { createAction, props } from "@ngrx/store";

export const updateOnlyParameter = createAction(
  "[Profile Questions Settings] Update only parameter",
  props<{ only: GreetingOnly }>(),
);

export const updateHashParameter = createAction(
  "[Profile Questions Settings] Update hash parameter",
  props<{ hash_id: string | null }>(),
);
