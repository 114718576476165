import { Injectable } from "@angular/core";
import { ToastService } from "@app/core/services/toast.service";
import { ProductDetailsService } from "@app/product-details/services/product-details.service";
import { DiaryDietarySupplementService } from "@app/profile/services/diary-dietary-supplement.service";
import * as fromCategoryFilter from "@app/shared/stores/category-filter/store";
import * as fromNutrientFilter from "@app/shared/stores/nutrient-filter/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import * as fromProduct from "../../store";
import * as DietarySupplementActions from "../actions/dietary-supplement.actions";
import * as DiarySettingSelects from "../selectors/diary-settings.selectors";
import * as DietarySupplementSelects from "../selectors/dietary-supplement.selectors";

@Injectable()
export class DietarySupplementEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DietarySupplementActions.loadProfileDSProducts,
        DietarySupplementActions.loadProfileDSProductsNextPage,
        DietarySupplementActions.setProfileDSProductFilter,
        fromCategoryFilter.applyCategoryFilterListRation,
        // fromNutrientFilter.applyNutrientFiltersDietarySupplement,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingSelects.selectRationId())),
        this.store.pipe(
          select(DietarySupplementSelects.selectProfileDSProductFilter()),
        ),
        this.store.pipe(
          select(fromNutrientFilter.selectAppliedNutrientFilterIds()),
        ),
        this.store.pipe(
          select(fromCategoryFilter.selectAppliedCategoryFilterIdList()),
        ),
      ]),
      switchMap(([action, rationId, productFilter, filters, categories]) => {
        const newCategories = (action as any)?.productFilter?.categories;
        productFilter = {
          ...productFilter,
          filters,
          categories: newCategories ?? categories,
        };

        return this.diaryDietarySupplementService
          .loadDietarySupplement(rationId, productFilter)
          .pipe(
            map((response) => {
              return DietarySupplementActions.loadProfileDSProductsSuccess({
                products: response.data,
                meta: response.meta,
                isAppend:
                  action.type ===
                  DietarySupplementActions.loadProfileDSProductsNextPage.type,
              });
            }),
            catchError((error) => {
              return of(
                DietarySupplementActions.loadProfileDSProductsFailure({
                  error,
                }),
              );
            }),
          );
      }),
    ),
  );

  addProductsDetailsToStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(DietarySupplementActions.addProfileDSProductsDetailsToStorage),
        tap(({ products }) =>
          this.productDetailsService.addProductsDetailsToStorage(products),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromProduct.State>,
    private diaryDietarySupplementService: DiaryDietarySupplementService,
    private productDetailsService: ProductDetailsService,
    private toastService: ToastService,
  ) {}
}
