import { Injectable } from "@angular/core";
import { BrowserStorageService } from "@app/core/services/browser-storage.service";

@Injectable()
export class DiaryDetailsService {
  readonly HASH_KEY = "hash_id";
  readonly ONLY_KEY = "only_key";

  constructor(private browserStorageService: BrowserStorageService) {}

  getHashId(): string | null {
    return this.browserStorageService.get(this.HASH_KEY);
  }

  setHashId(hashId: string): void {
    this.browserStorageService.set(this.HASH_KEY, hashId);
  }

  setOnlyKey(onlyKey: string | null): void {
    if (onlyKey) {
      this.browserStorageService.set(this.ONLY_KEY, onlyKey);
    } else {
      this.browserStorageService.remove(this.ONLY_KEY);
    }
  }

  getOnlyKey(): string | null {
    return this.browserStorageService.get(this.ONLY_KEY);
  }
}
