import { Injectable } from "@angular/core";
import {
  DiaryProductsRecipesService,
  DiaryProductsService,
  DiaryRecipesService,
} from "@app/profile/services";
import * as fromCategoryFilter from "@app/shared/stores/category-filter/store";
import * as fromNutrientFilter from "@app/shared/stores/nutrient-filter/store";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatLatestFrom } from "@ngrx/operators";
import { Store, select } from "@ngrx/store";
import { of } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import * as fromProduct from "../../store";
import * as ProductActions from "../actions/product.actions";
import * as DiarySettingSelects from "../selectors/diary-settings.selectors";
import * as ProductSelects from "../selectors/product.selectors";

@Injectable()
export class ProductEffects {
  loadProducts$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProductActions.loadProducts,
        ProductActions.loadProductsNextPage,
        ProductActions.setProductFilter,
        // fromCategoryFilter.applyCategoryFilterListRation,
        // fromNutrientFilter.applyNutrientFiltersRation,
      ),
      concatLatestFrom(() => [
        this.store.pipe(select(DiarySettingSelects.selectRationId())),
        this.store.pipe(select(ProductSelects.selectProductFilter())),
        this.store.pipe(
          select(fromNutrientFilter.selectAppliedNutrientFilterIds()),
        ),
        this.store.pipe(
          select(fromCategoryFilter.selectAppliedCategoryFilterIdList()),
        ),
      ]),
      switchMap(([action, rationId, productFilter, filters, categories]) => {
        productFilter = {
          ...productFilter,
          filters,
          categories,
        };

        return this.diaryProductsRecipesService
          .loadProducts(rationId, productFilter)
          .pipe(
            map((response) => {
              return ProductActions.loadProductsSuccess({
                products: response.data,
                isAppend:
                  action.type === ProductActions.loadProductsNextPage.type,
              });
            }),
            catchError((error) => {
              return of(ProductActions.loadProductsFailure({ error }));
            }),
          );
      }),
    ),
  );

  // loadProductRecommendations$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(ProductActions.loadProductRecommendations),
  //     concatLatestFrom(() => [
  //       this.store.pipe(select(DiarySettingSelects.selectRationId())),
  //       this.store.pipe(select(DiarySettingSelects.selectActiveEatingFoodId)),
  //     ]),
  //     switchMap(([_, rationId, eatingFoodId]) => {
  //       return this.diaryService
  //         .loadFoodRecommendations(rationId, eatingFoodId)
  //         .pipe(
  //           map((response) => {
  //             return ProductActions.loadProductRecommendationsSuccess({
  //               products: response.data,
  //             });
  //           }),
  //           catchError((error) => {
  //             return of(
  //               ProductActions.loadProductRecommendationsFailure({ error }),
  //             );
  //           }),
  //         );
  //     }),
  //   ),
  // );

  updateProductMass$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProductActions.updateProductMass),
      switchMap(({ product }) => {
        const { mass, product_id } = product;
        const service =
          product.type === "recipe"
            ? this.diaryRecipesService
            : this.diaryProductsService;

        return service.getUpdatedMass(+mass.toFixed(0), product_id).pipe(
          map((response) =>
            ProductActions.updateProductMassSuccess({
              product: response.data,
            }),
          ),
          catchError((error) =>
            of(ProductActions.updateProductMassFailure({ product, error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store<fromProduct.State>,
    private diaryProductsRecipesService: DiaryProductsRecipesService,
    private diaryProductsService: DiaryProductsService,
    private diaryRecipesService: DiaryRecipesService,
  ) {}
}
