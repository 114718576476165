import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BrowserStorageService } from "@app/core/services/browser-storage.service";
import { environment } from "@env/environment";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class ProfileService {
  private profileInfoKey = "nutrient-planer-profile";

  constructor(
    private http: HttpClient,
    private browserStorageService: BrowserStorageService,
  ) {}

  checkPatientHash(hash: any): Observable<any> {
    return this.http.post<any>(
      `${environment.primaryApiUrl}/patient/validate-link/`,
      { hash },
    );
  }

  checkPatientSecretWord(secretWord: string, patientId: any): Observable<any> {
    return this.http
      .post<any>(
        `${environment.primaryApiUrl}/patient/${patientId}/validate-secret-word/`,
        { "secret-word": secretWord },
      )
      .pipe(map((response) => response.data[0]));
  }

  getProfileInfoFromStorage(): Observable<any> {
    return this.browserStorageService.supported().pipe(
      map(() => this.browserStorageService.get(this.profileInfoKey)),
      map((value: string | null) => (value ? JSON.parse(value) : null)),
    );
  }

  addProfileInfoToStorage(profileInfo: any) {
    return this.getProfileInfoFromStorage().pipe(
      tap(() =>
        this.browserStorageService.set(
          this.profileInfoKey,
          JSON.stringify(profileInfo),
        ),
      ),
    );
  }
}
