import { EatingFood } from "@app/shared/models/ration-details/eating-food.model";
import { Eating } from "@app/shared/models/ration-details/eating.model";
import { NutrientsEssential } from "@app/shared/models/ration-details/nutrients-essential.model";
import { Timecard } from "@app/shared/models/ration-details/timecard.model";
import { createAction, props } from "@ngrx/store";

export const updateSelectedDayId = createAction(
  "[Diary Settings] Update current day ID",
  props<{ selectedDayId: number }>(),
);

export const updateActiveEatingId = createAction(
  "[Diary Settings] Update active eating ID",
  props<{ activeEatingId: number }>(),
);

export const updateActiveWeakId = createAction(
  "[Diary Settings] Update active weak ID",
  props<{ weakId: number }>(),
);

export const updateActiveRationId = createAction(
  "[Diary Settings] Update active ration ID",
  props<{ ration_id: number }>(),
);

export const loadRationDays = createAction("[Diary Settings] Load Ration Days");
export const loadRationDaysSuccess = createAction(
  "[Diary Settings] Load Ration Days Success",
  props<{ norms: any[]; week: any[]; eatings: any[] }>(),
);
export const loadRationDaysFailure = createAction(
  "[Diary Settings] Load Ration Days Failure",
  props<{ error: any }>(),
);

export const addDayRation = createAction("[Diary Settings] Add Day Ration");
export const addDayRationSuccess = createAction(
  "[Diary Settings] Add Day Ration Success",
  props<{ week: Timecard[] }>(),
);
export const addDayRationFailure = createAction(
  "[Diary Settings] Add Day Ration Failure",
  props<{ error: any }>(),
);

export const navigateToRationDay = createAction(
  "[Diary Settings] Navigate To Ration Day",
  props<{ dayId: number }>(),
);

export const removeDayRation = createAction(
  "[Diary Settings] Remove Day Ration",
  props<{ day: Timecard }>(),
);
export const removeDayRationSuccess = createAction(
  "[Diary Settings] Remove Day Ration Success",
  props<{ week: Timecard[] }>(),
);
export const removeDayRationFailure = createAction(
  "[Diary Settings] Remove Day Ration Failure",
  props<{ error: any }>(),
);

export const loadRationDay = createAction("[Diary Settings] Load Ration Day");
export const loadRationDaySuccess = createAction(
  "[Diary Settings] Load Ration Day Success",
  props<{
    norms: NutrientsEssential[];
    week: Timecard[];
    eatings: Eating[];
    description: string;
  }>(),
);
export const loadRationDayFailure = createAction(
  "[Diary Settings] Load Ration Day Failure",
  props<{ error: any }>(),
);

export const updateEatingRation = createAction(
  "[Diary Settings] Update Eating Ration",
  props<{ selectedEatingCard: Eating }>(),
);
export const updateEatingRationSuccess = createAction(
  "[Diary Settings] Update Eating Ration Success",
  props<{ eating: Eating }>(),
);
export const updateEatingRationFailure = createAction(
  "[Diary Settings] Update Eating Ration Failure",
  props<{ error: any }>(),
);

export const removeEatingRation = createAction(
  "[Diary Settings] Remove Eating Ration",
  props<{ selectedEatingCard: Eating }>(),
);
export const removeEatingRationSuccess = createAction(
  "[Diary Settings] Remove Eating Ration Success",
  props<{ norms: NutrientsEssential[]; selectedEatingCard: Eating }>(),
);
export const removeEatingRationFailure = createAction(
  "[Diary Settings] Remove Eating Ration Failure",
  props<{ error: any }>(),
);

export const updateFoodInEatingRation = createAction(
  "[Diary Settings] Update Food In Eating Ration",
  props<{ selectedFoodCard: EatingFood }>(),
);
export const updateFoodInEatingRationSuccess = createAction(
  "[Diary Settings] Update Food In Eating Ration Success",
  props<{ norms: NutrientsEssential[]; eating: Eating }>(),
);
export const updateFoodInEatingRationFailure = createAction(
  "[Diary Settings] Update Food In Eating Ration Failure",
  props<{ error: any }>(),
);

export const addEatingRation = createAction(
  "[Diary Settings] Add Eating Ration",
);
export const addEatingRationSuccess = createAction(
  "[Diary Settings] Add Eating Ration Success",
  props<{ eating: Eating }>(),
);
export const addEatingRationFailure = createAction(
  "[Diary Settings] Add Eating Ration Failure",
  props<{ error: any }>(),
);

export const toggleFoodInEatingRation = createAction(
  "[Diary Settings] Toggle Food In Eating Ration",
  props<{ selectedFoodCard: EatingFood }>(),
);

export const addFoodToEatingRation = createAction(
  "[Diary Settings] Add Food To Eating Ration",
  props<{ selectedFoodCard: EatingFood }>(),
);
export const addFoodToEatingRationSuccess = createAction(
  "[Diary Settings] Add Food To Eating Ration Success",
  props<{ norms: any[]; eating: any }>(),
);
export const addFoodToEatingRationFailure = createAction(
  "[Diary Settings] Add Food To Eating Ration Failure",
  props<{ error: any }>(),
);

export const removeFoodInEatingRation = createAction(
  "[Diary Settings] Remove Food In Eating Ration",
  props<{ selectedFoodCard: EatingFood }>(),
);
export const removeFoodInEatingRationSuccess = createAction(
  "[Diary Settings] Remove Food In Eating Ration Success",
  props<{ norms: NutrientsEssential[]; eating: Eating }>(),
);
export const removeFoodInEatingRationFailure = createAction(
  "[Diary Settings] Remove Food In Eating Ration Failure",
  props<{ error: any }>(),
);

export const loadWeeksForCopyEatingRation = createAction(
  "[Diary Settings] Load Weeks For Copy Eating Ration",
);
export const loadWeeksForCopyEatingRationSuccess = createAction(
  "[Diary Settings] Load Weeks For Copy Eating Ration Success",
  props<{ existingWeeksAndDays: Timecard[] }>(),
);
export const loadWeeksForCopyEatingRationFailure = createAction(
  "[Diary Settings] Load Weeks For Copy Eating Ration Failure",
  props<{ error: any }>(),
);

export const replaceFoodInEatingRation = createAction(
  "[Diary Settings] Replace Food In Eating Ration",
  props<{ selectedFoodCard: EatingFood }>(),
);
export const replaceFoodInEatingRationSuccess = createAction(
  "[Diary Settings] Replace Food In Eating Ration Success",
  props<{ norms: NutrientsEssential[]; eating: Eating }>(),
);
export const replaceFoodInEatingRationFailure = createAction(
  "[Diary Settings] Replace Food In Eating Ration Failure",
  props<{ error: any }>(),
);

export const updateDescriptionByEatingID = createAction(
  "[Diary Settings] Update Eating description By eating ID",
  props<{ description: string; eating: Eating }>(),
);
