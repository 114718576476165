import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Patient } from "@app/shared/models";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class QuestionnaireService {
  constructor(private http: HttpClient) {}

  updateQuestionnaire(patient: Patient) {
    return this.http.put<any>(
      `${environment.primaryApiUrl}/patient/questionnaire/update`,
      patient,
    );
  }

  loginPatientWithSecretWordAndHash(
    secret_word: string,
    hash: string,
  ): Observable<any> {
    return this.http.post(
      `${environment.primaryApiUrl}/patient/questionnaire/login`,
      { secret_word, hash },
    );
  }

  loadPatient(): Observable<any> {
    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/questionnaire/patient`,
    );
  }
}
