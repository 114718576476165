import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { environment } from "@env/environment";
import { Observable } from "rxjs";

@Injectable()
export class DiaryProductsRecipesService {
  readonly http = inject(HttpClient);

  loadProducts(
    rationId: number,
    productFilter: any,
    take = 50,
  ): Observable<any> {
    const { page, ...filterData } = productFilter;
    const { search, ...filter } = filterData;

    return this.http.get<any>(
      `${environment.primaryApiUrl}/patient/diary/${rationId}/product_recipe`,
      {
        params: {
          page,
          search,
          per_limit: take,
          filter: JSON.stringify(filter),
          general: 0,
        },
      },
    );
  }
}
