import { DietarySuppmentCategory } from "@app/shared/models/dietary-supplement/category.model";
import { Action, createReducer, on } from "@ngrx/store";
import * as DietarySupplementCategoriesActions from "../actions/dietary-supplement-categories.actions";

export const fromDietarySupplementCategoriesFeatureKey =
  "dietary-supplement-categories";

export interface State {
  isLoading: boolean;
  categories: DietarySuppmentCategory[];
}

export const initialState: State = {
  isLoading: false,
  categories: [],
};

export const compareProductsReducer = createReducer(
  initialState,

  on(DietarySupplementCategoriesActions.loadDSCategories, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DietarySupplementCategoriesActions.loadDSCategoriesSuccess,
    (_, { categories }) => ({
      categories: categories,
      isLoading: false,
    }),
  ),
  on(
    DietarySupplementCategoriesActions.loadDSCategoriesFailure,
    (state, { error }) => ({
      ...state,
      isLoading: false,
      error,
    }),
  ),
);

export function reducer(state: State | undefined, action: Action) {
  return compareProductsReducer(state, action);
}

export const select = (state: State) => state.categories;
export const selectCategories = (state: State) => state.categories;
export const selectLoading = (state: State) => state.isLoading;
