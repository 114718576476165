import * as DiarySettings from "@app/profile/store/actions/diary-settings.actions";
import { Eating } from "@app/shared/models/ration-details/eating.model";
import { NutrientsEssential } from "@app/shared/models/ration-details/nutrients-essential.model";
import { Timecard } from "@app/shared/models/ration-details/timecard.model";
import { Action, createReducer, on } from "@ngrx/store";

export const diarySettingsFeatureKey = "diary settings";

export interface State {
  isLoading: boolean;
  selectedDayId: number;
  activeEatingId: number;
  weakId: number;
  description: string;
  ration_id: number;
  norms: NutrientsEssential[];
  week: Timecard[];
  weeks: Timecard[];
  eatings: Eating[];
}

export const initialState: State = {
  isLoading: false,
  selectedDayId: 1,
  activeEatingId: 0,
  weakId: 0,
  ration_id: 0,
  description: "",
  norms: [],
  week: [],
  weeks: [],
  eatings: [],
};

export const diarySettingsReducer = createReducer(
  initialState,

  on(DiarySettings.updateSelectedDayId, (state, { selectedDayId }) => ({
    ...state,
    selectedDayId,
  })),
  on(DiarySettings.updateActiveEatingId, (state, { activeEatingId }) => ({
    ...state,
    activeEatingId,
  })),
  on(DiarySettings.updateActiveWeakId, (state, { weakId }) => ({
    ...state,
    weakId,
  })),
  on(DiarySettings.updateActiveRationId, (state, { ration_id }) => ({
    ...state,
    ration_id,
  })),
  on(DiarySettings.loadRationDays, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.loadRationDaysSuccess,
    (state, { norms, week, eatings }) => ({
      ...state,
      isLoading: false,
      norms,
      week,
      eatings,
    }),
  ),
  on(DiarySettings.loadRationDaysFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.addDayRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DiarySettings.addDayRationSuccess, (state, { week }) => ({
    ...state,
    isLoading: false,
    week,
  })),
  on(DiarySettings.addDayRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.removeDayRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DiarySettings.removeDayRationSuccess, (state, { week }) => ({
    ...state,
    isLoading: false,
    week,
  })),
  on(DiarySettings.removeDayRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.loadRationDay, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.loadRationDaySuccess,
    (state, { norms, week, eatings, description }) => ({
      ...state,
      isLoading: false,
      norms,
      week,
      eatings: [...eatings].sort((eating1, eating2) =>
        eating1.time.localeCompare(eating2.time),
      ),
      description,
    }),
  ),
  on(DiarySettings.loadRationDayFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.updateEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DiarySettings.updateEatingRationSuccess, (state, { eating }) => {
    const eatings = state.eatings.map((item: any) =>
      item.id === eating.id ? { ...item, ...eating } : item,
    );
    eatings.sort((eating1, eating2) =>
      eating1.time.localeCompare(eating2.time),
    );

    return {
      ...state,
      isLoading: false,
      eatings,
    };
  }),
  on(DiarySettings.updateEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.removeEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.removeEatingRationSuccess,
    (state, { selectedEatingCard, norms }) => ({
      ...state,
      isLoading: false,
      norms,
      eatings: state.eatings.filter(
        (item: any) => item.id !== selectedEatingCard.id,
      ),
    }),
  ),
  on(DiarySettings.removeEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.addEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(DiarySettings.addEatingRationSuccess, (state, { eating }) => ({
    ...state,
    isLoading: false,
    eatings: [...state.eatings, eating],
  })),
  on(DiarySettings.addEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.loadWeeksForCopyEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.loadWeeksForCopyEatingRationSuccess,
    (state, { existingWeeksAndDays }) => ({
      ...state,
      isLoading: false,
      existingWeeksAndDays,
    }),
  ),
  on(DiarySettings.loadWeeksForCopyEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.addFoodToEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.addFoodToEatingRationSuccess,
    (state, { eating, norms }) => ({
      ...state,
      isLoading: false,
      norms,
      eatings: state.eatings.map((item: any) =>
        item.id === eating.id ? { ...item, ...eating } : item,
      ),
    }),
  ),
  on(DiarySettings.addFoodToEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.removeFoodInEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.removeFoodInEatingRationSuccess,
    (state, { eating, norms }) => ({
      ...state,
      isLoading: false,
      norms,
      eatings: state.eatings.map((item: any) =>
        item.id === eating.id ? { ...item, ...eating } : item,
      ),
    }),
  ),
  on(DiarySettings.removeFoodInEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(DiarySettings.replaceFoodInEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.replaceFoodInEatingRationSuccess,
    (state, { eating, norms }) => ({
      ...state,
      isLoading: false,
      norms,
      eatings: state.eatings.map((item: any) =>
        item.id === eating.id ? { ...item, ...eating } : item,
      ),
    }),
  ),
  on(DiarySettings.replaceFoodInEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(
    DiarySettings.updateDescriptionByEatingID,
    (state, { description, eating }) => ({
      ...state,
      eatings: state.eatings.map((item: any) =>
        item.id === eating.id ? { ...item, description } : item,
      ),
    }),
  ),
  on(DiarySettings.updateFoodInEatingRation, (state) => ({
    ...state,
    isLoading: true,
  })),
  on(
    DiarySettings.updateFoodInEatingRationSuccess,
    (state, { eating, norms }) => ({
      ...state,
      isLoading: false,
      norms,
      eatings: state.eatings.map((item: any) =>
        item.id === eating.id ? { ...item, ...eating } : item,
      ),
    }),
  ),
  on(DiarySettings.updateFoodInEatingRationFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
);

export function reducer(state: State | undefined, action: Action) {
  return diarySettingsReducer(state, action);
}

export const selectCurrentDayID = (state: State) => state.selectedDayId;
export const selectCurrentWeakID = (state: State) => state.weakId;
export const selectCurrentRationID = (state: State) => state.ration_id;
export const selectActiveEatingID = (state: State) => state.activeEatingId;
export const selectEatings = (state: State) => state.eatings;
export const selectWeeks = (state: State) => state.weeks;
export const selectWeek = (state: State) => state.week;
export const selectIsLoading = (state: State) => state.isLoading;
export const selectDescription = (state: State) => state.description;
